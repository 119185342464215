import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register Chart.js modules
ChartJS.register(ArcElement, Tooltip, Legend);

const SalesDoughnut = () => {
  // Sales data for different projects
  const projectSales = [3500, 4200, 90, 5000, 320];
  const projectNames = ["Project A", "Project B", "Project C", "Project D", "Project E"];

  // Chart data and configuration
  const data = {
    labels: projectNames,
    datasets: [
      {
        label: "Total Sales ($)",
        data: projectSales,
        backgroundColor: [
          "rgba(255, 0, 153, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            return `$${value.toLocaleString()}`;
          },
        },
      },
      title: {
        display: true,
        text: "Total Sales Per Project",
        font: {
          size: 18,
        },
      },
    },
  };

  return (
    <div style={{ width: "60%", margin: "0 auto" }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default SalesDoughnut;
