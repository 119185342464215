import Checkout from "../components/organism/Checkout/Checkout";
import YaayLogoSmall from '../components/atom/LogoSmall/LogoSmall';
import FooterNew from '../components/molecule/FooterNew/FooterNew';
import { useState, useEffect } from "react";
import { useShoppingBasket } from '../context/Context';
import ConfirmItem from "../components/molecule/ConfirmItem/ConfirmItem";
import Header from "../components/molecule/Header/Header";
import { useLocation } from "react-router-dom";

import './Confirmation.scss'


const ConfirmationPage = (props) => {
    
    //const { state, dispatch } = useShoppingBasket();
    const [ checkoutCost, setCheckoutCost] = useState({productCost: 0, delivery: 0, totalCost: 0})
    const {state} = useLocation();
    const {orderDetails, billAddress, shipAddress} = state; 
    const displayDetails= JSON.parse(orderDetails)
    const displayBillAdd= JSON.parse(billAddress)
    const displayShipAdd= JSON.parse(shipAddress)

    const RenderCheckoutItems = () => {
        //const returnItems = '';
        const { state, dispatch } = useShoppingBasket();
        const returnItems = state.items.map((item, index) => (
            <ConfirmItem item={item} key={index} />
        ))
        return returnItems;
    }

    const calculateCheckoutCost = () => {
        let prodTot = 0;
        let delivery = 0;
        let totalTot = 0;
        // state.items.map((item) => {
        //     prodTot = prodTot + ((item.itemCost*item.itemCount))
        // })
        totalTot = (prodTot + delivery);
        setCheckoutCost({productCost: prodTot.toFixed(2), delivery: delivery.toFixed(2), totalCost: totalTot.toFixed(2)})
    }

    useEffect(() => {
        calculateCheckoutCost()
    }, [state])
    
    return (
        <div id="container">
            <Header />
            <main>
                <section className="confirmWrapper">
                    <div className="confirmTitle">Order Confirmation</div>
                    <div className="confirmGreeting">Hey {displayDetails.payer.name.given_name} {displayDetails.payer.name.surname},</div>

                        <div className="confirmOuter">
                
                            <div className="confirmDetails">
                                
                                <div className="confirmContent"><p>We've got your order! Your world is about to look a whole lot better.</p>
                                <p>We'll drop you another email when your order ships.</p>
                                <p><strong>{displayDetails.payer.email_address}</strong></p>
                                </div>
                                <div className="confirmOrder">Order No: {displayDetails.id}</div>
                                <div>Placed on: {displayDetails.create_time}</div>
                                <table className="confirmTable">
                                    <tbody>
                                        {RenderCheckoutItems()}
                                    </tbody>
                                </table>
                                <div className="cart-summary">
                                    <p>Total Products: {checkoutCost.productCost}</p>
                                    <p>Delivery charges: {checkoutCost.delivery}</p>
                                    <h3>Grand Total: {checkoutCost.totalCost}</h3>
                                </div>
                            </div>
                
                            <div className="addressOuter">
                                <div className="addressDisplay ">
                                    <div className="confirmSubTitle">Billing Info</div>
                                    <div>{displayBillAdd.address_line_1}</div>
                                    <div>{displayBillAdd.admin_area_1}</div>
                                    <div>{displayBillAdd.admin_area_2}</div>
                                    <div>{displayBillAdd.postal_code}</div>
                                    <div>{displayBillAdd.country_code}</div>
                                </div>
                                <div className="addressDisplay ">
                                    <div className="confirmSubTitle">Shipping Info</div>
                                    <div>{displayShipAdd.address_line_1}</div>
                                    <div>{displayShipAdd.admin_area_1}</div>
                                    <div>{displayShipAdd.admin_area_2}</div>
                                    <div>{displayShipAdd.postal_code}</div>
                                    <div>{displayShipAdd.country_code}</div>
                                </div>
                            </div>
            
                        </div>
                   <div>If you need help with anything please don't hesitate to drop us an email: care@yaay.me</div>
                </section>
            </main>
        </div>
    )
}

export default ConfirmationPage;