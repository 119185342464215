import { useState, useRef, useEffect } from 'react';
import FontSelect from '../../atom/FontSelect/FontSelect';
import ColourPicker from '../../atom/ColourSelect/ColourSelect';
import './AddText.scss'

const AddText = ( props ) => {
    const { returnClick } = props;
    const curIndex = 1;
    const [setActive, seActiveState] = useState("");
    const [heightState, setHeightState] = useState("0px");
    const [textValue, setTextValue] = useState(null);
    const [fontValue, setFontValue] = useState(null);
    const [colourValue, setColourValue] = useState('#FF0099');
    const [openAccord, setOpenAccord] = useState(false);
    
    
    const [activeTab, setActiveTab] = useState(-1);
    
    const isActive = (activeTab === curIndex);

    const ref = useRef(null);

    const toggleAccordion = () => {
        setActiveTab(prev => activeTab === curIndex ? -1 : curIndex );
        
        setHeightState(
            isActive ? "0px" : `${ref.current.scrollHeight}px`
        );
    }

    const handleTextChange = (e) => {
        setTextValue(e.target.value);
    }

    const handleFontChange = (selectedFont) => {
        setFontValue(selectedFont);

    }

    const handleColourChange = (e) => {
        setColourValue(e.target.value);
    }

    const handleClick = () => {
        returnClick(textValue, fontValue, colourValue)
        setOpenAccord(!openAccord);

    }

    const handleSelectedColour = ( colourHex ) => {
        setColourValue(colourHex);
    }

    useEffect(() => {
        toggleAccordion();
    }, [openAccord])

    return (
        
        <div className="accordion_section" aria-expanded={ isActive }>
            <button className={`accordion ${isActive ? "active" : ""}`} onClick={() => toggleAccordion()}>
                <p className="accordion_title">Add Text</p>
            </button>

            <div
                ref={ref}
                style={{ maxHeight: `${heightState}` }}
                className="accordion_content"
            >
                <div className="accordion_text">
                    <div className='addTextForm'>
                        <label className="form-group">
                            <input id="studentName" type="text" className="form-control"  required onChange={handleTextChange} />
                            <span>Enter your Text</span>
                            <span className="border"></span>
                        </label>
                        <FontSelect textVal={textValue} returnClick={handleFontChange} location={'create'}/>
                        <ColourPicker setColour={handleSelectedColour} />
                        <div className='addTextButtonWrapper'>
                            <button onClick={handleClick} className='continueButton'>
                                Add Text
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddText;