import { getApp, initializeApp } from 'firebase/app';
import { getDatabase, ref, set, get  } from "firebase/database";
import { getStorage, getDownloadURL, uploadBytesResumable, ref as sRef } from "firebase/storage";
import axios from "axios";
import { operatingContext } from '../context/Context';
import { useContext } from 'react';
 
 const firebaseConfig = {
 apiKey: process.env.REACT_APP_FB_API_KEY,
 authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
 projectId: process.env.REACT_APP_FB_PROJECT_ID,
 storageBucket: process.env.REACT_APP_FB_STORAGEBUCKET,
 messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
 appId: process.env.REACT_APP_FB_APP_ID,
 measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
 databaseURL: "https://yaayinitial-default-rtdb.europe-west1.firebasedatabase.app/",
};
 
initializeApp(firebaseConfig)
const firebaseApp = getApp();
const db = getDatabase(firebaseApp);
const storage = getStorage(firebaseApp, firebaseConfig.storageBucket);


export const writeOrderData = async (userId, orderData) => {
    set(ref(db, 'orders/' + userId), orderData);
  }

  //****** Access Codes **********/

  export const writeNewAccessCode = async (aCode, createDate, maxUsage, codeAttributes) => {
    set(ref(db, 'accessCodes/' + aCode), {
      createDate: createDate,
      maxUsage: maxUsage,
      usage : [],
      locked: false,
      orderComplete: false,
      codeAttributes: codeAttributes
    });
  }

  export const readAccessCodes = async (path) => {
    const dbRef = ref(db, path);
    return get(dbRef).then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val();
      } else {

        return null;
      }
    }).catch((error) => {
      console.error("Error reading data:", error);
    });
  };

  // Write data to the database
    export const writeData = (path, data) => {
    return set(ref(db, path), data);
  };
  
  // Read data from the database
  export const readData = (path) => {
    const dbRef = ref(db, path);
    return get(dbRef).then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        return null;
      }
    }).catch((error) => {
      console.error("Error reading data:", error);
    });
  };
  
  // Update existing data in the database
//   export const updateData = (path, newData) => {
//     return update(ref(db, path), newData);
//   };
export const handleFirebaseStorage = async (uploadedImage) => {
    const baseURL = "https://uploadfile-pcgrjii2ia-ew.a.run.app";
    const fd = new FormData();
    fd.append('image', uploadedImage)
    const returnResponse = axios
      .post(baseURL, fd)
      .then((response) => {
        const returnURL = getDownloadURL(sRef(storage, uploadedImage.name))
        .then((url) => {
          // console.log('url', url)
            const retObject = {id: '', src: url, uri: 'uri', imgWidth: '', imgHeight: ''}
            //writeOrderData('12543', url)
            //const imageArray = imageAlbum;
            // const imageId = imageArray.length + 1;
            // imageArray.unshift({id: imageId, src: url, uri: 'uri', imgWidth: imageSize.width, imgHeight: imageSize.height})
            // setImageAlbum(imageArray);
            return url;
        })
        return returnURL;
    }).catch(error => {
        console.log('ERROR', error)
      });
    return returnResponse;
  }