import { useState, useEffect, useContext } from 'react';
import YaayLogoSmall from '../../atom/LogoSmall/LogoSmall';
import CartIcon from '../../atom/CartIcon/CartIcon';
import BasketAddModal from '../../atom/BasketAddModal/BasketAddModal';
import Drawer from '../../atom/Drawer/Drawer';
import { operatingContext } from '../../../context/Context';
import Breadcrumb from '../../atom/BreadCrumb/BreadCrumb';

import { client } from "../../../client";
import { ConnectButton } from "thirdweb/react";
import { useNavigate } from 'react-router-dom';
import { useActiveAccount, useWalletBalance } from "thirdweb/react";


import './Header.css';

const Header = (props) =>{
  const { showBasket = true } = props;
  const { openAddConfirm, setIsDrawerOpen} = useContext(operatingContext)
  const [scroll, setScroll] = useState(false);
  const navigate = useNavigate();
  const account = useActiveAccount();

  const openBasket = () => {
    setIsDrawerOpen(true)
  }

    useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 10);
      });
    });

    useEffect(() => {
        if (!account){
            navigate('/')
        }
    })

    const handleLogoClick = () => {
      if (!account){
        navigate('/dashboard')
      } else {
        navigate('/dashboard')
      }
    }
  
    return (
      <header>
          <div flex='1' className='headerWrapper'><YaayLogoSmall returnClick={() => handleLogoClick()} align={'center'}/></div>
          <div className='rightHeaderPanel'>
            {showBasket && 
              <div className='cartClick' onClick={openBasket}>
                <CartIcon />
              </div>
            }
          </div>
          <Breadcrumb />
          <ConnectButton client={client} />
          <BasketAddModal isOpen={openAddConfirm} />
          <Drawer />
        </header>
    )
  }

  export default Header;