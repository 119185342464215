import ImageBox from './ImageBox';
import { operatingContext, selectContext } from '../../../context/Context';
import './ImageAlbum.css'
import { useContext, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';

const ImageAlbum = (props) => {
    const { returnSelect = null } = props;
    const { imageAlbum } = useContext(operatingContext);
    const { selectedImage } = useContext(selectContext)
    const [localAlbum, setLocalAlbum] = useState(() => {
        if(imageAlbum.length<1){
          let imgAlbum = JSON.parse(localStorage.getItem('imageArray'));
          if(!imgAlbum){
            return [];
          } else {
            return imgAlbum;
          }
          
        } else {
          return imageAlbum;
        }
    }) 

    const handleImageSelect = (imgId) => {
        // setSelectedImage(null);
        // setSelectedImage(imgId);
        if (returnSelect){
          returnSelect(imgId);
        }
        
    }

    useEffect(() => {
        if(imageAlbum?.length===0){
            const imgAlbum = JSON.parse(localStorage.getItem('imageArray'));
            setLocalAlbum(imgAlbum);
          } else {
            setLocalAlbum(imageAlbum);
          }
    }, [imageAlbum])

    const renderImages = () => {
        if(!localAlbum || localAlbum?.length < 1) return
        const imageReturn = localAlbum?.map((image, index) => {
            let imgSizes = {width: image.imgWidth, height: image.imgHeight};
            return <ImageBox key={index} imgId={image.id} imgSrc={image.src} imgSize={imgSizes} isSelected={(selectedImage === image.id)?true:false} returnClick={handleImageSelect}></ImageBox>
        })
        return imageReturn;
    }

    return (
        <div className='albumPanel'>
            {(!localAlbum || localAlbum?.length===0) && <Box style={{color: '#ff0098'}}>To add an Image, upload using the form above</Box>}
            {renderImages()}
        </div>
    )
}

export default ImageAlbum;