import { useContext, useState, useEffect } from 'react';
import { operatingContext, selectContext } from '../context/Context';
import Header from '../components/molecule/Header/Header';
import axios from "axios";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    Box,
    Button
  } from '@chakra-ui/react'
import ImageAlbum from '../components/molecule/ImageAlbum/ImageAlbum';
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { getApp, initializeApp } from "firebase/app";
import { Link } from 'react-router-dom';
import { isValidFileExtension, getImageDPI, getPngDPI, getImageDetails, getImageDetailsFromURL, imageUrlToFile } from '../helpers/ImageHelpers';
import DragDropFile from '../components/molecule/DragDrop/DragDrop';
import ConfirmBox from '../components/atom/ConfirmBox/ConfirmBox';
import { FirebaseContext } from "../firebase/firebaseContext";

import { writeCookie, writeLocalStorage } from '../helpers/cookies';

import './Upload.css';

const Upload = () => {
    const [preview, setPreview] = useState()
    const {uploadedImage, setUploadedImage} = useContext(selectContext)
    const {confirmOpen, setConfirmOpen} = useContext(selectContext);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const {imageAlbum, setImageAlbum } = useContext(operatingContext);
    const [imageInfo, setImageInfo] = useState(null)
    const [imageSize, setImageSize] = useState({width: null, height: null });
    const {openUpload, setOpenUpload} = useContext(operatingContext);
    const {openURLUpload, setOpenURLUpload} = useContext(operatingContext);
    const {openDDUpload, setOpenDDUpload} = useContext(operatingContext);
    const [imageUrl, setImageUrl] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadedImageUrl, setUploadedImageUrl] = useState('');
    const [error, setError] = useState('');
    const [imageAssess, setImageAssess] = useState(false);

    //db Stuff
    const { handleFirebaseStorage } = useContext(FirebaseContext);
    const [ddFiles, setDDFiles] = useState(null);

  
    const firebaseConfig = {
        apiKey: 'AIzaSyAo70-9MM2aZCx5bVGrytpdLkdARg6TofU',
        authDomain: 'yaayinitial.firebaseapp.com',
        projectId: 'yaayinitial',
        storageBucket: 'yaayinitial.appspot.com',
        messagingSenderId: '468508283422',
        appId: '1:468508283422:web:4e0b1b195852c31185d40f',
        measurementId: 'G-2JTGLCZNNE'
      };

      //dbStuff

      const handleConfirm = result => {
        if (result) {

          if(result) {
            const findImageIndex = (element) => element.id === confirmOpen.imgId;
            imageAlbum.splice(imageAlbum.findIndex(findImageIndex), 1)
            setImageAlbum(imageAlbum)
            writeLocalStorage('imageArray', imageAlbum)
          }
        }
        
        setConfirmOpen({state: false, imgId: null});
      }

      useEffect(() => {
        if (!uploadedImage) {
            setPreview(undefined)
            setImageInfo(null)
            setImageSize({width: null, height: null })
            return
        }
    
        const objectUrl = URL.createObjectURL(uploadedImage)
        setPreview(objectUrl)
    
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [uploadedImage])

    const handleFileSelect = (e) =>{
      setUploadComplete(false);
        const file = e.target.files[0];
    if (file) {
        const ext = file.name.split('.').pop();
      if (isValidFileExtension(ext)) {
        getImageDetails(file, (info) => {
          setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
          //setUploadError('');
        });
        if (ext === 'png'){
            getPngDPI(file, (err, dpi) => {
                if (err) {
                  setUploadError(err.message);
                  setUploadedImage(null);
                  setImageInfo(null);
                } else {
                  setImageInfo(dpi.x);
                  setUploadError('');
                  setUploadedImage(file);
                }
              });
        } else {
            getImageDPI(file, (dpi) => {  
                setImageInfo((dpi.x.numerator/dpi.x.denominator));
                setUploadError('');
              });
            setUploadedImage(file)
        }
        if(imageInfo>140){
          setImageAssess(true)
        } else {
          setImageAssess(false)
        }
        setUploadError(null);
      } else {
        setUploadedImage(null);
        setUploadError('Invalid file type. Only images and PDFs are allowed.');
      }
    }
        
    }

    // ***** Upload to Firebase storage *****
  const handleUploadToStorage = async () => {
    setIsUploading(true);
    setUploadComplete(false)
    await handleFirebaseStorage(uploadedImage).then((response) => {
            let imageArray = new Array(...imageAlbum);
            const local = JSON.parse(localStorage.getItem('imageArray'));
            if (imageAlbum.length===0 && local?.length>0){
              imageArray = local;
            }
            const imageId = imageArray.length + 1;
            imageArray.unshift({id: imageId, src: response, imgWidth: imageSize.width, imgHeight: imageSize.height})
            console.log('adding to album', imageArray)
            setImageAlbum(imageArray);
            // setLocalAlbum(imageArray)
            writeLocalStorage('imageArray', imageArray)
            handleUpload(imageId);
      }).catch(error => {
        console.log('ERROR', error)
      });
  }

  const handleDDUploadToStorage = async(uploadFile) => {
    setIsUploading(true);
    setUploadComplete(false)
    const baseURL = "https://uploadfile-pcgrjii2ia-ew.a.run.app";
    const fd = new FormData();
    fd.append('image', uploadFile)
    axios
      .post(baseURL, fd)
      .then((response) => {
        initializeApp(firebaseConfig)
        const firebaseApp = getApp();
        const storage = getStorage(firebaseApp, firebaseConfig.storageBucket);
        getDownloadURL(ref(storage, uploadFile.name))
        .then((url) => {
            const imageArray = imageAlbum;
            const imageId = imageArray.length + 1;
            imageArray.unshift({id: imageId, src: url, imgWidth: imageSize.width, imgHeight: imageSize.height})
            setImageAlbum(imageArray);
            handleUpload(imageId);
        })
      }).catch(error => {
        console.log('ERROR', error)
      });
  }

  const handleURLUpload = async () => {
    const ext = imageUrl.split('.').pop();
    const imgFile = await imageUrlToFile(imageUrl);
    if (isValidFileExtension(ext)) {
      try {
        const response = await fetch(imageUrl);
        
        if (!response.ok) {
          throw new Error('Failed to fetch image.');
        } else {
          const img = new Image();
          img.src = imageUrl;
  
          img.onload = () => {
            setUploadedImage(imgFile);
            getImageDetailsFromURL(imageUrl, (err, info) => {
              if (err) {
                setError(err.message);
                // setImageDetails(null);
              } else {
                setError('');
                //setImageDetails(details);
                setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
                setImageInfo((info.dpi.x));
              }
            });
          }
        
          // img.onload = () => {
          //   
          //   // getImageDetails(file, (info) => {
          //   //   setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
          //   //   //setUploadError('');
          //   // });
          // }
        }
  
        initializeApp(firebaseConfig)
        const firebaseApp = getApp();
        const storage = getStorage(firebaseApp, firebaseConfig.storageBucket);
  
        const blob = await response.blob();
        const fileName = imageUrl.split('/').pop();
        //const storageRef = ref(storage, `images/${fileName}`);
        const storageRef = ref(storage, fileName);
        const uploadTask = uploadBytesResumable(storageRef, blob);
  
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('progress', progress)
            setUploadProgress(progress);
          },
          (error) => {
            console.log('ERROR', error.message)
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              const imageArray = imageAlbum;
              const imageId = imageArray.length + 1;
              imageArray.unshift({id: imageId, src: url, imgWidth: imageSize.width, imgHeight: imageSize.height})
              setImageAlbum(imageArray);
              setImageInfo(null)
              setImageSize({width: null, height: null })
              handleUpload(imageId);
            });
          }
        );
      } catch (err) {
        console.log('ERROR', err)
      }
    } else {
      setUploadedImage(null);
      setUploadError('Invalid file type. Only images and PDFs are allowed.');
    }
  };

  const handleUpload = (imageId) => {
    console.log('handleUpload')
    setIsUploading(true);
    const baseURL = "https://api.core3d.io/v1/uploads";
    const fd = new FormData();
    fd.append('file', uploadedImage)
    axios
      .post(baseURL, fd, { headers: { Authorization: `Bearer c3dak_NQCudjy8_8uZnX4oa6H9vEMWnirg29A2s` } })
      .then((response) => {
        setIsUploading(false);
        const index = imageAlbum.findIndex(item => item.id === imageId);
        const current = imageAlbum;
        setImageAlbum(current)
        setUploadComplete(true)
        //setSelectedImage(imageId)
        setPreview(null)
        setImageInfo(null)
        setImageSize({width: null, height: null });
        setTimeout(setOpenDDUpload(false), 3000);
      }).catch(error => {
        console.log('ERROR', error)
        setUploadComplete(false);
      });
  }

  const handleUploadClose = () => {
    setOpenUpload(false);
    setOpenURLUpload(false);
    setOpenDDUpload(false);
    setUploadedImage(null);
  }

  const handleDragDrop = async (files) => {
    console.log('handleDragDrop')
    const file = files[0];
    if (file) {
      setOpenDDUpload(true)
      const ext = file.name.split('.').pop();
      if (isValidFileExtension(ext)) {
        getImageDetails(file, (info) => {
          setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
          //setUploadError('');
        });
        if (ext === 'png'){
            getPngDPI(file, (err, dpi) => {
                if (err) {
                  setUploadError(err.message);
                  setUploadedImage(null);
                  setImageInfo(null);
                } else {
                  setImageInfo(dpi.x);
                  setUploadError('');
                  setUploadedImage(file);
                }
              });
        } else {
            getImageDPI(file, (dpi) => {  
                setImageInfo((dpi.x.numerator/dpi.x.denominator));
                setUploadError('');
              });
            setUploadedImage(file)
        }
        const objectUrl = URL.createObjectURL(file)
        if(imageInfo>140){
          setImageAssess(true)
        } else {
          setImageAssess(false)
        }
        setUploadError(null);
        setDDFiles([{file: file, imageURL: objectUrl, imageSize: 'imageDetails', imageInfo: imageInfo, imageAssess: imageAssess}]);

      } else {
        setUploadedImage(null);
        setUploadError('Invalid file type. Only images and PDFs are allowed.');
      }
    }
    
    // ********************************
    // Original multi file handling
    // const dragFiles = [...files];
    // setOpenDDUpload(true)
    // const ddFileArray = []

    // dragFiles.forEach(file => {
    //   let imageDetails, imageInfo, imageAssess
    //   if (file) {
        
    //     const ext = file.name.split('.').pop();
    //     if (isValidFileExtension(ext)) {
          
    //       imageDetails = getImageDetails(file, (info) => {
    //         //setImageSize({width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight});
    //         imageDetails = {width: info.width, height: info.height, realWidth: info.realWidth, realHeight: info.realHeight}
    //       })
    //       if (ext === 'png'){
    //           getPngDPI(file, (err, dpi) => {
    //               if (err) {
    //                 setUploadError(err.message);
    //                 // setUploadedImage(null);
    //                 // setImageInfo(null);
    //               } else {
    //                 setUploadError('');
    //                 //setImageInfo(dpi.x);
    //                 imageInfo = dpi.x
    //                 //setUploadedImage(file);
    //               }
    //             });
    //       } else {
    //           getImageDPI(file, (dpi) => {  
    //               //setImageInfo((dpi.x.numerator/dpi.x.denominator));
    //               imageInfo = (dpi.x.numerator/dpi.x.denominator)
    //               setUploadError('');
    //             });
    //           //setUploadedImage(file)
    //       }
    //       const objectUrl = URL.createObjectURL(file)
    //       if(imageInfo>140){
    //         imageAssess = true
    //       } else {
    //         imageAssess = false
    //       }
    //       setUploadError(null);
    //       ddFileArray.push({file: file, imageURL: objectUrl, imageSize: imageDetails, imageInfo: imageInfo, imageAssess: imageAssess})
    //     } else {
    //       setUploadedImage(null);
    //       setUploadError('Invalid file type. Only images and PDFs are allowed.');
    //     }
    //   }
    // })
  }

  const handleBrowseReturn = () => {
    setOpenUpload(true)
  }

  const handleURLReturn = () => {
    setOpenURLUpload(true)
  }

  async function processItems(items){
    for (const file of items) {
      console.log('processing', file)
      console.log('in processItems')
      await handleDDUploadToStorage(file.file);
    }
    console.log('Finished processing')
  }

  const handleDDUpload = () => {
    if(!ddFiles){
      setError('No files to upload')
      return;
    }
    console.log('processing items')
    processItems(ddFiles)
  }

  useEffect(() => {
    if (!uploadedImage) {
        setPreview(undefined)
        setImageInfo(null)
        setImageSize({width: null, height: null })
        return
    }

    const objectUrl = URL.createObjectURL(uploadedImage)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
}, [uploadedImage])

    return (
    <div id="container">
        {/* <Header /> */}
        <Header/>
            
        <div className='uploadOuter'>
            <ConfirmBox text={'Delete Image?'} open={confirmOpen.state} handleConfirm={handleConfirm}/>
            <div className={`topDrawer ${(openUpload)?'topDrawerOpen':''}`}>
              <div className='detailTitle'>Image File Upload</div>
              <div className='uploadButtonHolder'>
                <label htmlFor="fileUpload" className="continueButton">Add Image</label>
                <input id='fileUpload' type='file' onChange={handleFileSelect}/>
              </div>
              {/* <div className='drawerImageDisplay'>
                  {(uploadedImage && preview) &&  <div style={{'aspectRatio': 'auto'}} ><img src={preview} style={{'height' : '150px'}} alt='Uploaded' /></div> }
              </div> */}
              <div className='drawerDisplayOuter'>
                  <div className='drawerImageDisplay'>
                    {!preview && <div className='NoImage'>Select an image to see a preview</div>}
                    {(uploadedImage && preview) &&  <div style={{'aspectRatio': 'auto'}} ><img src={preview} style={{'height' : '150px'}} alt='Uploaded' /></div> }
                  </div>
                  {/* {uploadProgress > 0 && <p>Upload Progress: {uploadProgress.toFixed(2)}%</p>}
                  {error && <p style={{ color: 'red' }}>{error}</p>} */}
                  <div className='uploadStats'>
                    <div className='detailTitle'>Image Details</div>
                    <div>Size: {(imageSize.width)?`${imageSize.width}px x ${imageSize.height}px`:''}</div>
                    <div>Resolution: {(imageInfo)? `${imageInfo}dpi`:''}</div>
                    <div className='imageAssess'>
                      {(imageAssess===true) && <div className='itsGood'>That image looks all good.</div>}
                    </div>
                  </div>
                </div>
                <div className='imageErrorPanel'>
                  {uploadError&&<div className='uploadError'>{uploadError}</div>}
                  {(isUploading)?<div className='uploading'>Uploading...</div>: ''}
                  {(uploadComplete)?<div className='uploaded'>Image Uploaded to Album.<br/><span className='uploadSubText'>To upload another image, click the button above.</span></div>:''}
                </div>
              {/* <div className='uploadStats'>
                <div className='detailTitle'>Image Details</div>
                <div>Size: {(imageSize.width)?`${imageSize.width}px x ${imageSize.height}px (${imageSize.realWidth?.toFixed(2)}cm x ${imageSize.realHeight?.toFixed(2)}cm)`:''}</div>
                <div>Resolution: {(imageInfo)? `${imageInfo}dpi`:''}</div>
              </div>
              <div className='uploadMessage'>
              {(isUploading)?<div className='uploading'>Uploading...</div>: ''}
              {(uploadComplete)?<div className='uploaded'>Image Uploaded.</div>:''}
              </div> */}
              <div className='buttonHolder'>
                <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadToStorage} isDisabled={(isUploading || !preview)?true:false}>Upload to Album</Button>
                <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadClose} isDisabled={(isUploading)?true:false}>Close</Button>
              </div>
            </div>
            {/* URL Upload */}
            <div className={`topDrawer ${(openURLUpload)?'topDrawerOpen':''}`}>
              {uploadError}
              <div className='urlUploadOuter'>
                <div className='detailTitle'>Image URL Uploader</div>
                <input
                  className='urlUpload'
                  type="text"
                  placeholder="Enter image URL"
                  value={imageUrl}
                  onChange={(e) => setImageUrl(e.target.value)}
                />
                <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3}  onClick={handleURLUpload}>Upload Image</Button>
                <div className='drawerDisplayOuter'>
                  <div className='drawerImageDisplay'>
                    {!preview && <div className='NoImage'>Select an image to see a preview</div>}
                    {(uploadedImage && preview) &&  <div style={{'aspectRatio': 'auto'}} ><img src={preview} style={{'height' : '150px'}} alt='Uploaded' /></div> }
                  </div>
                  {/* {uploadProgress > 0 && <p>Upload Progress: {uploadProgress.toFixed(2)}%</p>}
                  {error && <p style={{ color: 'red' }}>{error}</p>} */}
                  <div className='uploadStats'>
                    <div className='detailTitle'>Image Details</div>
                    <div>Size: {(imageSize.width)?`${imageSize.width}px x ${imageSize.height}px (${imageSize.realWidth?.toFixed(2)}cm x ${imageSize.realHeight?.toFixed(2)}cm)`:''}</div>
                    <div>Resolution: {(imageInfo)? `${imageInfo}dpi`:''}</div>
                  </div>
                </div>
                <div className='uploadMessage'>
                {(isUploading)?<div className='uploading'>Uploading...</div>: ''}
                {(uploadComplete)?<div className='uploaded'>Image Uploaded.</div>:''}
                </div>
                <div className='buttonHolder'>
                  <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadToStorage} disabled={(isUploading || !preview)?true:false}>Upload to Album</Button>
                  <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadClose} disabled={(isUploading)?true:false}>Close</Button>
                </div>
              </div>
            </div>
            {/* DD Upload */}
            <div className={`topDrawer ${(openDDUpload)?'topDrawerOpen':''}`}>
              {uploadError}
              <div className='urlUploadOuter'>
                <div className='detailTitle'>Image Uploader</div>
                 <div className='drawerDisplayOuter'>
                  {/* <div className='drawerImageDisplay'>
                    {!preview && <div className='NoImage'>Select an image to see a preview</div>}
                    {(uploadedImage && preview) &&  <div style={{'aspectRatio': 'auto'}} ><img src={preview} style={{'height' : '150px'}} alt='Uploaded' /></div> }
                  </div>
                  <div className='uploadStats'>
                    <div className='detailTitle'>Image Details</div>
                    <div>Size: {(imageSize.width)?`${imageSize.width}px x ${imageSize.height}px (${imageSize.realWidth?.toFixed(2)}cm x ${imageSize.realHeight?.toFixed(2)}cm)`:''}</div>
                    <div>Resolution: {(imageInfo)? `${imageInfo}dpi`:''}</div>
                  </div> */}
                  <div className='fileListOuter'>
                    {ddFiles?.map((file, index) => {
                      return (
                        <div className='fileListRow'>
                          <div style={{'aspectRatio': 'auto'}}><img src={file.imageURL} style={{'height' : '80px'}} alt='Uploaded' /></div>
                          <div className='ddDetailsPanel'>
                            <div className='detailTitle'>Image Details</div>
                            {console.log(file)}
                            <div>Size: {(file?.imageDetails?.width)?`${file?.imageDetails?.width}px x ${file?.imageDetails?.height}px (${file?.imageDetails?.realWidth?.toFixed(2)}cm x ${file?.imageDetails?.realHeight?.toFixed(2)}cm)`:''}</div>
                            <div>Resolution: {(file.imageInfo)? `${file.imageInfo}dpi`:''}</div>
                          </div>
                          <div className='imageCheck'>
                            {file.imageAssess}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  
                </div>
                <div className='uploadMessage'>
                {(isUploading)?<div className='uploading'>Uploading...</div>: ''}
                {(uploadComplete)?<div className='uploaded'>Image Uploaded.</div>:''}
                </div>
                <div className='buttonHolder'>
                  <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadToStorage} disabled={(isUploading || !preview)?true:false}>Upload to Album</Button>
                  <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUploadClose} disabled={(isUploading)?true:false}>Close</Button>
                </div>
              </div>
            </div>
            
            <div className='uploadTop'>
              <div className='dragDrop'>
                <DragDropFile returnDragDrop={handleDragDrop}/>
              </div>
            </div>
            <Accordion defaultIndex={0} style={{borderTop: '#ff0098', color: '#ff0098'}}>
                <AccordionItem>
                    <h2>
                    <AccordionButton>
                        <Box as='span' flex='1' textAlign='left'>
                            Default Album
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    {/* <AccordionPanel pb={4} style={{'overflow': 'hidden', 'display': 'block', 'opacity': 1, 'height': 'auto'}}>
                    </AccordionPanel> */}
                </AccordionItem>
            </Accordion>
            <ImageAlbum />
            </div>
            <div className='drawerButtons rightButton'>
                {/* {(localAlbum.length < 1) &&
                    <span className='continueButton continueDisabled'>select merchandise</span>
                }
                {(localAlbum.length > 0) && */}
                    <Link className='continueButton' style={{'background': '#ff0098'}} to={`/merch`}>select merchandise</Link>
                {/* } */}
            </div>
        </div>

    )
}

export default Upload;