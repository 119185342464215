import React from "react";
import { selectContext } from "../../../context/Context";
import UniRNSS_F_White from '../../../assets/images/MerchTSRoundFront.png'
import UniRNSS_F_Charcoal from '../../../assets/images/MerchImages/MerchUniTSRNSSFront_Charcoal.png'
import UniRNSS_F_Grey from '../../../assets/images/MerchImages/MerchUniTSRNSSFront_Grey.png'
import UniRNSS_F_Navy from '../../../assets/images/MerchImages/MerchUniTSRNSSFront_Navy.png'
import UniRNSS_F_Red from '../../../assets/images/MerchImages/MerchUniTSRNSSFront_Red.png'

import UniSweatShirt_F_White from '../../../assets/images/MerchImages/MerchUniLSSweatFront_White.png'
import UniSweatShirt_F_Charcoal from '../../../assets/images/MerchImages/MerchUniLSSweatFront_Charcoal.png'
import UniSweatShirt_F_Grey from '../../../assets/images/MerchImages/MerchUniLSSweatFront_Grey.png'
import UniSweatShirt_F_Navy from '../../../assets/images/MerchImages/MerchUniLSSweatFront_Blue.png'
import UniSweatShirt_F_Red from '../../../assets/images/MerchImages/MerchUniLSSweatFront_Red.png'

import UniPOHoodie_F_White from '../../../assets/images/MerchImages/MerchUniPOHoodieFront_White.png'
import UniPOHoodie_F_White_Over from '../../../assets/images/MerchImages/MerchUniPOHoodieFront_White_Over.png'
import UniPOHoodie_F_Charcoal from '../../../assets/images/MerchImages/MerchUniPOHoodieFront_Charcoal.png'
import UniPOHoodie_F_Grey from '../../../assets/images/MerchImages/MerchUniPOHoodieFront_Grey.png'
import UniPOHoodie_F_Navy from '../../../assets/images/MerchImages/MerchUniPOHoodieFront_Blue.png'
import UniPOHoodie_F_Red from '../../../assets/images/MerchImages/MerchUniPOHoodieFront_Red.png'

import TSB from '../../../assets/images/MerchTSBack.png'
import VNTSF from '../../../assets/images/MerchTSVeeFront.png'
import POHDF from '../../../assets/images/MerchHDPullOverFront.png'
import HDB from '../../../assets/images/MerchHDBack.png'
import ZPHDF from '../../../assets/images/MerchHDZipFront.png'
import ColourPanelMini from "../ColourPanelMini/ColourPanelMini";

import LdsRNSSTF from '../../../assets/images/MerchImages/MerchLdsRNSSTeeFront.png'
import LdsRNSSTR from '../../../assets/images/MerchImages/MerchLdsRNSSTeeBack.png'
import LdsVNSSTF from '../../../assets/images/MerchImages/MerchLdsVNSSTeeFront.png'
import LdsVNSSTR from '../../../assets/images/MerchImages/MerchLdsVNSSTeeBack.png'
import UniRNLSTF from '../../../assets/images/MerchImages/MerchUniRNLSTeeFront.png'
import UniRNLSTR from '../../../assets/images/MerchImages/MerchUniRNLSTeeBack.png'
import LdsRNLSTF from '../../../assets/images/MerchImages/MerchLdsRNLSTeeFront.png'
import LdsRNLSTR from '../../../assets/images/MerchImages/MerchLdsRNLSTeeBack.png'
import UniVestF from '../../../assets/images/MerchImages/MerchUniVestFront.png'
import UniVestR from '../../../assets/images/MerchImages/MerchUniVestBack.png'

import ldsVestF from '../../../assets/images/MerchImages/MerchLdsVestFront.png'
import ldsVestR from '../../../assets/images/MerchImages/MerchLdsVestBack.png'
import ldsPOHoodieF from '../../../assets/images/MerchImages/MerchLdsPOHoodieFront.png'
import ldsPOHoodieR from '../../../assets/images/MerchImages/MerchLdsPOHoodieBack.png'
import ldsZipHoodieF from '../../../assets/images/MerchImages/MerchLdsZipHoodieFront.png'
import ldsZipHoodieR from '../../../assets/images/MerchImages/MerchLdsZipHoodieBack.png'
import UniLSSweaterF from '../../../assets/images/MerchImages/MerchUniLSSweatFront.png'
import UniLSSweaterR from '../../../assets/images/MerchImages/MerchUniLSSweatBack.png'
import { writeLocalStorage } from "../../../helpers/cookies";

import './MerchPanel.css'
import MerchCarousel from "../MerchCarousel/MerchCarousel";

const MerchPanel = () => {
    const {selectedMerch, setSelectedMerch} = React.useContext(selectContext);

    const boxes = [
        {sku: 'SKU7364287634', type: 'TRound', name: 'Unisex Round Neck T-Shirt', show: true, desc: 'Classic short sleeve T-Shirt made from soft and comfortable cotton, ideal for everyday use.', images: [{id:1, colour: '#ffffff', images: [{id: 1, name: UniRNSS_F_White, over: null}, {id: 2, name: TSB, over: null}]}, {id:2, colour: '#000000', images: [{id: 1, name: UniRNSS_F_Charcoal, over: null}, {id: 2, name: TSB, over: null}]}, {id:3, colour: '#c5c5c5', images: [{id: 3, name: UniRNSS_F_Grey, over: null}, {id: 3, name: TSB, over: null}]}, {id:4, colour: '#303549', images: [{id: 4, name: UniRNSS_F_Navy, over: null}, {id: 4, name: TSB, over: null}]}, {id:5, colour: '#c42b20', images: [{id: 5, name: UniRNSS_F_Red, over: null}, {id: 5, name: TSB, over: null}]}], colours: ['#ffffff', '#000000','#c5c5c5','#303549','#c42b20'], rectDims: {rectboxX: 211, rectboxY: 60,rectboxWidth: 180, rectboxHeight: 225}},
        {sku: 'SKU7333347634', type: 'TVNeck', name: 'Unisex V Neck T-Shirt', show: false,  desc: 'This premium V-neck made with soft and comfortabke cotton, is ideal for everyday use. ', images: [{id:1, colour: '#ffffff', images: [{id: 1, name: VNTSF}, {id:2, name: TSB}]}], colours: ['#ffffff', '#000000','#c5c5c5','#303549','#c42b20'], rectDims: {rectboxX: 210, rectboxY: 60,rectboxWidth: 180, rectboxHeight: 225}},
        {sku: 'SKU7643287634', type: 'HoodiePlain', name: 'Unisex Pull Over Hoodie', show: true,  desc: 'This pullover Hoodie is made with a cotton polyester mix and features a drawstring, kangaroo pocket, ribbed cuffs and waistband.  ', images: [{id:1, colour: '#ffffff', images: [{id: 1, name: UniPOHoodie_F_White, over: UniPOHoodie_F_White_Over}, {id: 2, name: TSB}]}, {id:2, colour: '#000000', images: [{id: 1, name: UniPOHoodie_F_Charcoal}, {id: 2, name: TSB}]}, {id:3, colour: '#c5c5c5', images: [{id: 3, name: UniPOHoodie_F_Grey}, {id: 3, name: TSB}]}, {id:4, colour: '#303549', images: [{id: 4, name: UniPOHoodie_F_Navy}, {id: 4, name: TSB}]}, {id:5, colour: '#c42b20', images: [{id: 5, name: UniPOHoodie_F_Red}, {id: 5, name: TSB}]}], colours: ['#ffffff', '#000000','#c5c5c5','#303549','#c42b20'], rectDims: {rectboxX: 208, rectboxY: 80,rectboxWidth: 180, rectboxHeight: 180}},
        {sku: 'SKU7762727634', type: 'HoodieZip', name: 'Unisex Zip Hoodie', show: false,  desc: 'This premium zipped hoodie is made of a blend of high quality  materials and features a full front zip. ', images: [{id:1, colour: '#ffffff', images: [{id: 1, name: ZPHDF, over: null}, {id:2, name: HDB}]}], colours: ['#ffffff', '#000000','#c5c5c5','#303549','#c42b20'], rectDims: {rectboxX: 210, rectboxY: 60,rectboxWidth: 180, rectboxHeight: 225}},
        {sku: 'SKU4455287634', type: 'LdsRNSST', name: 'Ladies Round Neck T-Shirt', show: false,  desc: 'Classic short sleeve T-Shirt made from soft and comfortable cotton, ideal for everyday use.', images: [{id:1, colour: '#ffffff', images: [{id: 1, name: LdsRNSSTF}, {id:2, name: LdsRNSSTR}]}], colours: ['#ffffff', '#000000','#c5c5c5','#303549','#c42b20'], rectDims: {rectboxX: 210, rectboxY: 60,rectboxWidth: 180, rectboxHeight: 225}},
        {sku: 'SKU7362333411', type: 'LdsVNSST', name: 'Ladies V Neck T-Shirt', show: false,  desc: 'This premium V-neck made with soft and comfortabke cotton, is ideal for everyday use. ', images: [{id:1, colour: '#ffffff', images: [{id: 1,  name: LdsVNSSTF}, {id:2, name: LdsVNSSTR}]}], colours: ['#ffffff', '#000000','#c5c5c5','#303549','#c42b20'], rectDims: {rectboxX: 210, rectboxY: 60,rectboxWidth: 180, rectboxHeight: 225}},
        {sku: 'SKU7364277675', type: 'UniRNLST', name: 'Unisex Round Neck Long Sleeve T-Shirt', show: false,  desc: 'This pullover Hoodie is made with a cotton polyester mix and features a drawstring, kangaroo pocket, ribbed cuffs and waistband.  ', images: [{id:1, colour: '#ffffff', images: [{id: 1, name: UniRNLSTF}, {id:2, name: UniRNLSTR}]}], colours: ['#ffffff', '#000000','#c5c5c5','#303549','#c42b20'], rectDims: {rectboxX: 210, rectboxY: 60,rectboxWidth: 180, rectboxHeight: 225}},
        {sku: 'SKU7322309894', type: 'LdsRNLST', name: 'Ladies Round Neck Long Sleeve T-Shirt', show: false,  desc: 'This premium zipped hoodie is made of a blend of high quality  materials and features a full front zip. ', images: [{id:1, colour: '#ffffff', images: [{id: 1, name: LdsRNLSTF}, {id:2, name: LdsRNLSTR}]}], colours: ['#ffffff', '#000000','#c5c5c5','#303549','#c42b20'], rectDims: {rectboxX: 210, rectboxY: 60,rectboxWidth: 180, rectboxHeight: 225}},
        {sku: 'SKU7223349989', type: 'UniVest', name: 'Unisex Vest', show: false,  desc: 'This premium zipped hoodie is made of a blend of high quality  materials and features a full front zip. ', images: [{id:1, colour: '#ffffff', images: [{id: 1, name: UniVestF}, {id:2, name: UniVestR}]}], colours: ['#ffffff', '#000000','#c5c5c5','#303549','#c42b20'], rectDims: {rectboxX: 205, rectboxY: 90,rectboxWidth: 180, rectboxHeight: 225}},
        {sku: 'SKU7554512894', type: 'LdsVest', name: 'Ladies Vest', show: false,  desc: 'This premium V-neck made with soft and comfortabke cotton, is ideal for everyday use. ', images: [{id:1, colour: '#ffffff', images: [{id: 1, name: ldsVestF}, {id:2, name: ldsVestR}]}], colours: ['#ffffff', '#000000','#c5c5c5','#303549','#c42b20'], rectDims: {rectboxX: 210, rectboxY: 60,rectboxWidth: 180, rectboxHeight: 225}},
        {sku: 'SKU7344217634', type: 'ldsPOHoodie', name: 'Ladies Pull Over Hoodie', show: false,  desc: 'This pullover Hoodie is made with a cotton polyester mix and features a drawstring, kangaroo pocket, ribbed cuffs and waistband.  ', images: [{id:1, colour: '#ffffff', images: [{id: 1, name: ldsPOHoodieF}, {id:2, name: ldsPOHoodieR}]}], colours: ['#ffffff', '#000000','#c5c5c5','#303549','#c42b20'], rectDims: {rectboxX: 210, rectboxY: 60,rectboxWidth: 180, rectboxHeight: 225}},
        {sku: 'SKU7578827893', type: 'LdsZipHoodie', name: 'Ladies Zip Hoodie', show: false,  desc: 'This premium zipped hoodie is made of a blend of high quality  materials and features a full front zip. ', images: [{id:1, colour: '#ffffff', images: [{id: 1, name: ldsZipHoodieF}, {id:2, name: ldsZipHoodieR}]}], colours: ['#ffffff', '#000000','#c5c5c5','#303549','#c42b20'], rectDims: {rectboxX: 210, rectboxY: 60,rectboxWidth: 180, rectboxHeight: 225}},
        {sku: 'SKU7364555643', type: 'UniLSSweater', name: 'Unisex Sweater', show: true,  desc: 'This premium zipped hoodie is made of a blend of high quality  materials and features a full front zip. ', images: [{id:1, colour: '#ffffff', images: [{id: 1, name: UniSweatShirt_F_White}, {id: 2, name: TSB}]}, {id:2, colour: '#000000', images: [{id: 1, name: UniSweatShirt_F_Charcoal}, {id: 2, name: TSB}]}, {id:3, colour: '#c5c5c5', images: [{id: 3, name: UniSweatShirt_F_Grey}, {id: 3, name: TSB}]}, {id:4, colour: '#303549', images: [{id: 4, name: UniSweatShirt_F_Navy}, {id: 4, name: TSB}]}, {id:5, colour: '#c42b20', images: [{id: 5, name: UniSweatShirt_F_Red}, {id: 5, name: TSB}]}], colours: ['#ffffff', '#000000','#c5c5c5','#303549','#c42b20'], rectDims: {rectboxX: 210, rectboxY: 65,rectboxWidth: 180, rectboxHeight: 225}},
    ]

    const merch = [
        { id: 1, 
          sku: 'SKU7364287634', 
          ItemImage: [{id:1, name: UniRNSS_F_White}, {id:2, name: TSB}],
          setupImage: [{id:1, name: UniRNSS_F_White}, {id:2, name: TSB}],
          setupName: 'Premium Regular Fit Round Neck Cotton T-Shirt', 
          ItemName: 'Premium Regular Fit Round Neck Cotton T-Shirt', 
          ItemCost: '22.95', 
          colours: ['#ffffff', '#c42b20', '#000000'], 
          sizes: [{size:'small', label: 's', increase: 0}, {size:'medium', label: 'm', increase: 0}, {size:'large', label: 'l', increase: 0}, {size:'x-large', label: 'xl', increase: 0}, {size:'xx-large', label: 'xxl', increase: 0.8}, {size:'xxxl', label: 'xxxl', increase: 1.44}],
          blurb: "Classic short sleeve T-Shirt made from soft and comfortable cotton, ideal for everyday use. The design is available in a variety of colours.",
          showSize: true },
        { id: 2, 
          sku: 'SKU7364287635', 
          ItemImage: [{id:1, name: VNTSF}, {id:2, name: TSB}], 
          setupImage: [{id:1, name: VNTSF}, {id:2, name: TSB}], 
          setupName: 'Premium Regular Fit V Neck Cotton T-Shirt',
          ItemName: 'Premium Regular Fit Round Neck Cotton T-Shirt', 
          ItemCost: '22.95', 
          colours: ['#ffffff', '#c42b20','#000000'], 
          sizes: [{size:'small', label: 's', increase: 0}, {size:'medium', label: 'm', increase: 0}, {size:'large', label: 'l', increase: 0}, {size:'x-large', label: 'xl', increase: 0}, {size:'xx-large', label: 'xxl', increase: 0.8}, {size:'xxxl', label: 'xxxl', increase: 1.44}],
          blurb: "This premium V-neck made with soft and comfortabke cotton, is ideal for everyday use. ",
          showSize: true },
        { id: 3, 
          sku: 'SKU7364287636', 
          ItemImage: [{id:1, name: POHDF}, {id:2, name: HDB}], 
          setupImages: [{id:1, name: POHDF}, {id:2, name: HDB}], 
          setupName: 'Premium pullover Hoodie',
          ItemName: 'Rugby Rebels Sports Water Bottle', 
          ItemCost: '44.95', 
          colours: ['#ffffff'], 
          sizes: [{size:'one size', label: 'one size', increase: 0}],
          blurb: "This pullover Hoodie is made with a cotton polyester mix and features a drawstring, kangaroo pocket, ribbed cuffs and waistband.  ",
          showSize: false },
      ];

    const handleBoxClick = (index, block) => {
        //setisExpanded(isExpanded => !isExpanded)
        setSelectedMerch(block)
        writeLocalStorage('selectedMerch', block)
    }

    const renderBlocks = () => {
        const returnBlocks = boxes.map((block, index) => {
            if(block.show === false) return;
            let blockStyle
            if((block.type) === selectedMerch?.type){
                blockStyle = 'merchItemPanel merchItemPanelSelected';
            } else {
                blockStyle = 'merchItemPanel';
            }

            return ( 
                // 
                //     <div className={blockStyle} onClick={() => handleBoxClick(index, block)}>
                //         <img className="baseImage" src={block.image} alt="" />
                //     </div>
                // </li>
                <li key={index}>
                    <div className={blockStyle} onClick={() => handleBoxClick(index, block)}>
                        <div className='merchItemTitle'>{block.name}</div>
                        <div className="merchItemImage">
                            <MerchCarousel images={block.images[0]} />
                        </div>
                        
                        <div className="merchItemDesc">
                            {block.desc}
                        </div>
                        <div className="availColours">Available colours</div>
                        <ColourPanelMini />
                    </div>
                </li>
            )
        })
        return returnBlocks;
    }

    return (
        <div className="merchPanelOuter">
            <ul className='wrapPanel'>
                {renderBlocks()}
            </ul>
        </div>
    )
}

export default MerchPanel;