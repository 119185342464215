import CodeGenerator from '../../../Tools/CodeGenerator/CodeGenerator';
import DashSection from '../../atom/DashSection/DashSection';
import OverviewWallet from '../OverviewWallet/OverviewWallet';
import SalesChart from '../SalesChart/SalesChart';
import SalesDoughnut from '../SalesDoughnut/SalesDoughnut';
import { Link, useNavigate } from "react-router-dom";
import './Dashboard.scss';

const Dashboard = () => {

    const navigate = useNavigate();

    const handleReturnClick = () => {
        console.log('I have been clicked');
        navigate('/upload');
    }

    return (
        <div className='dashOuter'>
            <h1>Dashboard</h1>
            <div className='dashInner'>
                <div className='edgeCol'>
                    <DashSection title={'Account Details'}>
                        Details Here
                    </DashSection>
                    <DashSection title={'Projects'}>
                        Details Here
                    </DashSection>
                    <DashSection title={'Drops'}>
                        Details Here
                    </DashSection>
                    <DashSection title={'Albums'}>
                        Details Here
                    </DashSection>
                </div>
                <div className='midCol'>
                    <DashSection title={'Sales Revenue'}>
                        <SalesChart />
                        <SalesDoughnut />
                    </DashSection>
                </div>
                <div className='edgeCol'>
                    <DashSection blurb={'Feeling creative? Jump right in.. '} buttonText={'create'} returnClick={handleReturnClick}></DashSection>
                    <DashSection title={'Wallet'}>
                        <OverviewWallet />
                    </DashSection>
                    <DashSection title={'Code Generator'}>
                        <CodeGenerator />
                    </DashSection>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;