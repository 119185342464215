import './AnimatesButtonWrapper.scss'
const AnimatedButtonWrapper = (props) => {
    const { children } = props;
    return (
        <div className='aniButtonWrapper'>
            <span> </span>
            <span> </span>
            <span> </span>
            <span> </span>
            <div>{children}</div>
        </div>
    )
};

export default AnimatedButtonWrapper;