import { useLayoutEffect, useRef, useState } from 'react'
import { Circle }from 'react-konva'

const Selector = ( props ) => {
    const { x, y, colour, dragBound, getColour } = props;

    const circleRef = useRef(null);
    const {attr, setAttr} =  useState({x: 0, })
    
    function onDrag(e){
      var _x = e.target.attrs.x
      var _y = e.target.attrs.y
      getColour(_x,_y)
    }
  
    
      return (
        <Circle ref={circleRef}
          radius={20} stroke='white' strokeWidth={1} fill={colour} shadowBlur={4} draggable
          onDragStart={onDrag} onDragMove={onDrag}
          dragBoundFunc={dragBound}
          transformsEnabled={'position'}
          x={x}
          y={y}
        />
      )
    
  }

  export default Selector;