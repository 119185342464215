import { useContext, useState, useEffect, useRef } from 'react';
import SliderToggle from '../../atom/Toggle/Toggle';
import { selectContext, operatingContext } from '../../../context/Context';
import './ImageThumbPanel.css'
import { Button } from '@chakra-ui/react';
import ImageAlbum from '../ImageAlbum/ImageAlbum';

const ImageThumbPanel = ( props ) => {
    const { returnClick } = props;
    const {uploadedImage, setUploaded, setIsOpen} = useContext(selectContext);
    const [heightState, setHeightState] = useState("0px");
    const {setIsDrawerOpen} = useContext(operatingContext);
    const curIndex = 1;
    const [preview, setPreview] = useState(null)
    const [activeTab, setActiveTab] = useState(-1);
    
    const isActive = activeTab === curIndex;

    const ref = useRef(null);

    const toggleAccordion = () => {
        setActiveTab(prev => activeTab === curIndex ? -1 : curIndex );
        
        setHeightState(
        isActive ? "0px" : `${ref.current.scrollHeight}px`
        );
    }
    
    const handleImageSelect = (imgId) => {
        returnClick(imgId);
    }

    const handleNewImage = () => {
        //setUploaded(null);
        setIsDrawerOpen(true);
    }

    useEffect(() => {
        if (!uploadedImage) {
            setPreview(undefined)
            return
        }
    
        const objectUrl = URL.createObjectURL(uploadedImage)
        setPreview(objectUrl)
    
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [uploadedImage])

    return (
        <div className='imageThumbPanel'>
            {/* <div className='imageThumbLeft'>
                <div className='imageThumbOuter'>
                    <div style={{width: '160px'}}><SliderToggle /></div>
                </div>
            </div> */}
            <div className="accordion_section" aria-expanded={ isActive }>
            <button className={`accordion ${isActive ? "active" : ""}`} onClick={() => toggleAccordion()}>
                <p className="accordion_title">Image Album</p>
            </button>

            <div
                ref={ref}
                style={{ maxHeight: `${heightState}` }}
                className="accordion_content"
            >
                <div className='imageThumbOuter'>
                    <div className='imageThumbHeader'>
                        {/* <div><Button style={{'background': '#ff0098', marginRight: 0, marginBottom: '4px'}} colorScheme='blue' mr={3} onClick={handleNewImage}>+</Button></div> */}
                    </div>
                    <div className='ImageWrapper'>
                        {/* <div className='imageDisplay'>
                            {uploadedImage &&  <img src={preview} className='thumbImage' style={{'aspect-ratio': 'auto'}} alt='Uploaded' /> }
                        </div> */}
                        <ImageAlbum returnSelect={handleImageSelect}/>
                    </div>
                </div>
            </div>
            </div>

        </div>
    )
}

export default ImageThumbPanel;