import './SliderControl.scss';

const SliderControl = ( props ) => {
    const {value, onSliderChange , min = 0, max = 100, step = 1} = props

    const handleChange = (event) => {   
        onSliderChange(event.target.value); // Pass value to parent on change
      };

    return (
        <div id="slidecontainer">
            <div className='smallA'>A</div>
            <input 
                type="range" 
                min={min} 
                max={max} 
                step={step} 
                className="slider"
                value={value}
                onChange={handleChange} />
            <div className='bigA'>A</div>
        </div>
      )

}

export default SliderControl;