import Cookies from 'js-cookie';

export const writeCookie = (cookieName, newData) => {
    
    const existingCookie = Cookies.get(cookieName);

    if (existingCookie) {
        // If the cookie exists, set it in the state
      } else {
        // If the cookie does not exist, create a new one
        Cookies.set(cookieName, newData, { expires: 7 }); // Expires in 7 days
      }
};

export const readCookie = ( cookiename ) => {
    const checkCookie = Cookies.get(cookiename);
    return checkCookie;
}

// Function to clear the selection and delete the cookie
export const clearCookie = () => {
    Cookies.remove('userSelection');
};

export const writeLocalStorage = (name, newData) => {
    localStorage.setItem(name, JSON.stringify(newData));
}

export const readLocalStorage = (name) => {
    const saved = localStorage.getItem(name);
    return JSON.parse(saved);
}


