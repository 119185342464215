import './DashSection.scss'

const DashSection = (props) => {
    const { title, blurb, buttonText = null, returnClick, children, forceWidth = false} = props;

    const handleButtonClick = () => {
        returnClick();
    }

    return (
        <div className='dashSection' >
            {(title) &&
                <div className='sectionTitle'>{title}</div>
            }
            {(children) &&
                <div className='child'>{children}</div>
            }
            {(buttonText && returnClick) &&
                <div className='sectionButtonWrapper'>
                    <div className='sectionBlurb'>
                        {blurb}
                    </div>
                    <div className='sectionButtonRight'>
                        <button className='continueButton' onClick={() => handleButtonClick()}>{buttonText}</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default DashSection;