import { useEffect, useRef, useState } from 'react';
import { Stage, Layer } from 'react-konva';
import tinycolor from "tinycolor2";
import Selector from './Selector';
import './ColourSelect.scss'

const ColourPicker = (props) => {
    const { setColour, selectColour = null, location = 'create' } = props;
    const layerRef = useRef(null);
    const [isActive, setIsActive] = useState(false)
    const [ colourAttrib, setColourAtrrib ] = useState('hsl(324,100%,50%)');
    const [ hueAttrib, setHueAttrib ] = useState(324);
    const [ satAttrib, setSatAttrib ] = useState(100);
    const [ lightAttrib, setLightAttrib ] = useState(50)

    const presetColours = [
      {
        name: 'Black',
        colour: {
          h: 180,
          s: 100,
          l: 0
        }
      },
      {
        name: 'White',
        colour: {
          h: 180,
          s: 100,
          l: 100
        }
      },
      {
        name: 'Red',
        colour: {
          h: 0,
          s: 100,
          l: 50
        }
      },
      {
        name: 'Blue',
        colour: {
          h: 245,
          s: 100,
          l: 50
        }
      },
    ]
    
    const drawSample = () => {
        const canvas = layerRef.current.canvas;
        
        const ctx = canvas.getContext("2d");
        
        const color = tinycolor(colourAttrib);
        const hex = '#' + color.toHex().toUpperCase();
        
        ctx.clearRect(95,85,110,130)
        ctx.fillStyle = 'lightgray'
        ctx.fillRect(95,85,110,130)
        ctx.fillStyle = colourAttrib
        ctx.fillRect(100,90,100,100);
        
        ctx.font = "20px sans-serif";
        ctx.fillStyle = 'gray'
        ctx.fillText(hex, 107, 210);
      }
      
    const getHue = (x,y) => {
        const angleDeg = Math.floor((Math.atan2(150 - y, 150 - x) * 180 / Math.PI) + 180);
        setHueAttrib(angleDeg);
        setColourAtrrib(`hsl(${angleDeg},${satAttrib}%,${lightAttrib}%)`)
        drawSaturationSlider()
        drawLightSlider()
        drawSample()
      }
      
    const getSaturation = (x,y) => {
        const offset = 50;
        const height = 200;
        const saturation = Math.round(-y/2 + 125)
        setSatAttrib(saturation)
        setColourAtrrib(`hsl(${hueAttrib},${saturation}%,${lightAttrib}%)`)
        drawHueSlider()
        drawLightSlider()
        drawSample()
      }
      
    const getLight = (x,y) => {
        const offset = 50;
        const height = 200;
        const light = Math.round(-y/2 + 125)
        setLightAttrib(light);
        setColourAtrrib(`hsl(${hueAttrib},${satAttrib}%,${light}%)`)
        drawHueSlider()
        drawSaturationSlider()
        drawSample()
      }
      
    const saturationDragBound = (pos) => {
        const top = 50
        const height = 200
        const bottom = height + top
        return {
          x: 365,
          y: pos.y < top? top : pos.y> bottom? bottom : pos.y 
        }
      }
      
    const hueDragBound = (pos) => {
        const x = 150;
        const y = 150;
        const radius = 110;
        
        if (pos.x === x && pos.y === y) return {x: x + radius, y: y}
        else {
            const scale = radius / Math.sqrt(Math.pow(pos.x - x, 2) + Math.pow(pos.y - y, 2));
          return {
            y: Math.round((pos.y - y) * scale + y),
            x: Math.round((pos.x - x) * scale + x)
          }
        }
      }
      
    const lightDragBound = (pos) => {
        const top = 50
        const height = 200
        const bottom = height + top
        return {
          x: 445,
          y: pos.y < top? top : pos.y> bottom? bottom : pos.y 
        }
      }
      
    const drawLightSlider = () => {
        const canvas = layerRef.current.canvas;
        const ctx = canvas.getContext("2d");
        ctx.clearRect(425,0,40,300)
        
        ctx.beginPath()
        ctx.arc(445,50,15,0,360,true)
        ctx.fillStyle = `hsl(${hueAttrib},${lightAttrib}%,100%)`;
        // ctx.fillStyle = 'hsl(0,0%,100%)';
        ctx.fill()
        
        ctx.beginPath()
        ctx.arc(445,250,15,0,180,false)
        ctx.fillStyle = `hsl(${hueAttrib},${lightAttrib}%,0%)`;
        // ctx.fillStyle = 'hsl(0,0%,0%)';
        ctx.fill()
        
        for(let i=0;i<=100;i++) {
          ctx.fillStyle = `hsl(${hueAttrib},${satAttrib}%,${i}%)`;
          // ctx.fillStyle = 'hsl(0,0%,'+i+'%)';
          ctx.fillRect(430,250-2*i,30,2);
        }
      }
      
    const drawSaturationSlider = () => {
        const canvas = layerRef.current.canvas;
        const ctx = canvas.getContext("2d");
        ctx.clearRect(350,0,40,300)
        ctx.beginPath()
        ctx.arc(365,50,15,0,360,true)
        ctx.fillStyle = `hsl(${hueAttrib},100%,50%)`;
        ctx.fill()
        
        ctx.beginPath()
        ctx.arc(365,250,15,0,180,false)
        ctx.fillStyle = `hsl(${hueAttrib},0%,50%)`;
        ctx.fill()
        
        for(let i=0;i<=100;i++) {
          ctx.fillStyle = `hsl(${hueAttrib},${i}%,50%)`;
          ctx.fillRect(350,250-2*i,30,2);
        }
      }
      
    const drawHueSlider = () => {
        const canvas = layerRef.current.canvas;
        const ctx = canvas.getContext("2d");
        const x = 150;
        const y = 150;
        const radius = 125;
        const counterClockwise = false;
        
        ctx.clearRect(0,0,300,300)
        
        for(let angle=0; angle<360; angle++){
            const startAngle = (angle-1)*Math.PI/180;
            const endAngle = (angle+1) * Math.PI/180;
            ctx.beginPath();
            ctx.moveTo(x, y);
            ctx.arc(x, y, radius, startAngle, endAngle, counterClockwise);
            ctx.closePath();
            ctx.fillStyle = 'hsl('+angle+','+'100'+'%,'+'50'+'%)';
            ctx.fill();
        }
    
        ctx.beginPath();
        ctx.arc(x,y,radius,0,360)
        ctx.closePath();
        
        ctx.beginPath();
        ctx.arc(x,y,radius-30,0,360)
        ctx.closePath();
        ctx.fillStyle = '#EBEBEB'
        ctx.fill();
      }

      const handleClick = (colourObj) => {
        setColourAtrrib(`hsl(${colourObj.colour.h},${colourObj.colour.s}%,${colourObj.colour.l}%)`);
        setHueAttrib(colourObj.colour.h);
        setSatAttrib(colourObj.colour.s);
        setLightAttrib(colourObj.colour.l);
      }

      const renderColours = () => {
        const returnColours = presetColours.map((colour, index) => {
          const color = tinycolor(`hsl(${colour.colour.h},${colour.colour.s}%,${colour.colour.l}%)`);
          const hex = '#' + color.toHex().toUpperCase();
          return <div key={index} style={{background: hex}} className={`preset`} onClick={() => handleClick(colour)}></div>
        })
        return returnColours;
      }

      const handleColourSelect = () => {
        const color = tinycolor(colourAttrib);
        const hex = '#' + color.toHex().toUpperCase(); 
        setColour(hex);
        setIsActive(false);
      }

      const generateHex = (attrib) => {
        const color = tinycolor(colourAttrib);
        const hex = '#' + color.toHex().toUpperCase(); 
        return hex;
      }
      
      useEffect(() => {
        drawHueSlider()
        drawSaturationSlider()
        drawLightSlider()
        drawSample()
      })
      
        return (
          <div className={`select-menu ${(isActive)?'active': ''}`} style={{'marginTop': '11px'}}>
            <div className="select-btn" onClick={() => setIsActive(true)}>
                {selectColour && 
                  <span className="sBtn-text">Select your Colour</span>}

                {!selectColour &&
                  <div className='selectedColourWrapper'>
                    Colour:
                    <div className='preset' style={{background: generateHex(colourAttrib)}} onClick={() => setIsActive(true)}></div>
                  </div>
                }
                <i className="bx bx-chevron-down"></i>
            </div>
            <div className='optionsWrapper' style={(location==='edit')?{right: 0}:{left: '10px'}}>
              <div className='uploadTitle'>Choose your Text Colour</div>
              <div className='colourSwabs'>
                <div className='horizAlign'>Choose a default colour:</div>
                <div className='horizAlign'>{renderColours()}</div>
              </div>
              <div className='horizAlign'>or create your own custom colour:</div>
              <div className='hueText'>Hue: </div>
              <div className='satText'>Sat: </div>
              <div className='brightText'>Bright: </div>
              <div id='background'>
                <div id='stage'>
                    <Stage width={500} height={300} >
                        <Layer ref={layerRef} />
                        <Layer>
                        <Selector getColour={getHue} colour={colourAttrib} dragBound={hueDragBound} x={240} y={90} />
                        </Layer>
                        <Layer>
                        <Selector getColour={getSaturation} colour={colourAttrib} dragBound={saturationDragBound} x={365} y={50} />
                        </Layer>
                        <Layer>
                        <Selector getColour={getLight} colour={colourAttrib} dragBound={lightDragBound} x={445} y={150} />
                        </Layer>
                    </Stage>
                </div>
              </div>
              <div className='addTextButtonWrapper'>
                  <button onClick={() => handleColourSelect()}  className='continueButton'>
                      select Colour
                  </button>
              </div>
            </div>
        </div>
        )
    
}

export default ColourPicker;