
import { removeBackground } from "@imgly/background-removal";

export const removeBackg = async (image_src) =>  {
  
  const newImage = await removeBackground(image_src).then((blob) => {
    // The result is a blob encoded as PNG. It can be converted to an URL to be used as HTMLImage.src
    console.log('original', blob)
    const url = URL.createObjectURL(blob);
    return url;
  })
  return newImage;
}
