import { useActiveAccount, useWalletBalance } from "thirdweb/react";
import { sepolia } from "thirdweb/chains";
import { client } from "../../../client";

import './OverviewWallet.scss'

const OverviewWallet = () => {
    const account = useActiveAccount();
    console.log("wallet address", account?.address);
    
    const { data: balance, isLoading } = useWalletBalance({ client: client, chain: sepolia, address: account?.address,});
    console.log(account, balance);
    return (
        <div className="walletOuter">
            <div className="subject">Balance:</div>
            <div className="walletBalance">
                <div className="balanceTotal">{balance?.displayValue}</div>
                <div className="balanceSymbol">{balance?.symbol}</div>
            </div>
            <div className="subject">Address:</div>
            <div className="walletAddress">{account?.address}</div>
        </div>
    )
}

export default OverviewWallet;