import Header from '../components/molecule/Header/Header';
import ReviewStars from '../components/atom/ReviewStars/ReviewStars';
import ProductBlurb from '../components/atom/ProductBlurb/ProductBlurb';
import SizePanel from '../components/molecule/SizePanel/SizePanel';
import ColourPanel from '../components/molecule/ColourPanel/ColourPanel';
import BuyButton from '../components/atom/BuyButton/BuyButton';

import { useShoppingBasket, selectContext, operatingContext } from '../context/Context';

import TShirtRed from '../assets/images/shop/VRCTShirt1Red.png';

import { calcItemCost } from '../helpers/costHelpers';


import './ProductFull.scss'
import { useEffect, useState, useContext } from 'react';

const ProductFull = ( props ) => {

    const { title, product } = props;
    const {selectedColour, selectedSize} = useContext(selectContext);
    const {currentSelection, setCurrentSelection} = useContext(selectContext);
    const { setOpenAddConfirm, currency } = useContext(operatingContext);
    const[mainImageSrc, setMainImageSrc] = useState(TShirtRed)
    const { state, dispatch } = useShoppingBasket();

    const handleAdd = (id, itemName, sku, itemCost, itemSize, itemColour, itemCount, itemImage) => {
        dispatch({
          type: 'ADD_ITEM',
          payload: { id: id, name: itemName, sku: sku, itemCost: itemCost, itemColour, itemSize: itemSize, itemCount: itemCount, itemImage: itemImage }
        });
      };
    
      const handleEdit = (id, newCount) => {
        dispatch({ 
          type: 'EDIT_ITEM', 
          payload: { id: id, updates: { itemCount: newCount } } });
      };

    const handleBasketAdd = () => {
        const itemName = currentSelection.itemName;
        const sku = currentSelection.sku
        const size = selectedSize;
        const colour = selectedColour;
        const itemCost = calcItemCost(currentSelection.itemCost, selectedSize, currentSelection.sizes);;
        const itemImage = mainImageSrc
        const id = `${currentSelection.sku}-${selectedSize}-${selectedColour}`
        const result = state.items.filter(obj => { return obj.id === id})
        if(result.length === 0){
          handleAdd(id, itemName, sku, itemCost, size, colour, 1, itemImage);
        } else {
          const currentCount = result[0].itemCount;
          handleEdit(id, currentCount + 1)
        }
        setCurrentSelection(
            {itemName: itemName, 
            sku: sku, 
            size: selectedSize, 
            colour: selectedColour, 
            itemCost: currentSelection.itemCost,
            availColours: currentSelection.availColours,
            availImages: currentSelection.availImages,
            sizes: currentSelection.sizes,
            blurb: currentSelection.blurb,
            id:`${sku}-${size}-${colour}`})
        setOpenAddConfirm(true);
      }

    // useEffect(() => {
    //     if(selectedColour === '#ffffff'){
    //         setMainImageSrc(TShirtWhite)
    //     } else if (selectedColour === '#000000'){
    //         setMainImageSrc(TShirtBlack)
    //     } else {
    //         setMainImageSrc(TShirtRed)
    //     }
    // }, [selectedColour])
    useEffect(() => {
        const imgIndex = currentSelection.availColours.findIndex((element) => element === selectedColour)
        setMainImageSrc(currentSelection.availImages[imgIndex])

    }, [selectedColour])

    useEffect(() => {
        setMainImageSrc(currentSelection.availImages[0])
    }, [])

    const prodBlurb = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla a dolor sed dictum. In pretium malesuada justo. Quisque sit amet ex a sem dictum pharetra in vel neque. Cras suscipit nunc in nibh fringilla scelerisque. Fusce ut ligula commodo, viverra tortor vitae, dictum metus. Nulla hendrerit placerat posuere.'

    return (
        <div id="container">
            <Header/>
            <div className='productFullOuter'>
                <section id="product-info">
                    <div className="item-info-parent">
                        <div className="main-info">
                            <h4>{currentSelection.itemName}</h4>
                            {/* <ReviewStars score={4.23} /> */}
                            <ProductBlurb blurb={currentSelection.blurb} />
                        </div>
                        <div className="select-items">
                            <div className='sideBySide'>
                                <SizePanel showBreakdown={false} disabled={!currentSelection.showSize}/>
                                <div className='colourPanelOuter'>
                                    Colour:
                                    <ColourPanel colours={currentSelection.availColours} />
                                    <div className='buyOuter'>
                                        <div className='shopPriceWrapper'>
                                            Price: <span className="shopPrice">{currency.symbol}{calcItemCost(currentSelection.itemCost, selectedSize, currentSelection.sizes)}</span>
                                        </div>
                                        <div  className='buyHolder'>
                                            <button className='continueButton' onClick={handleBasketAdd}>add to basket</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="description">
                            <div className='descTitle'>Additional Info</div>
                            <ul>
                                <li>Care Instructions: Machine Wash</li>
                                <li>Fit Type: Classic Fit</li>
                                <li>Color name: Black-White</li>
                                <li>Material: Cotton</li>
                                <li>Pattern: Solid</li>
                            </ul>
                        </div> */}
                    </div>
                    <div className="item-image-parent">
                        <div className="item-image-main">
                            <img src={mainImageSrc} alt="default" />
                        </div>
                        {/* <div className="item-list-vertical">
                            <div className="thumb-box">
                                <img src="https://i.ibb.co/VJf6fXm/thumb1.jpg" alt="thumbnail" />
                            </div>
                            <div className="thumb-box">
                                <img src="https://i.ibb.co/Jt5zc58/thumb2.jpg" alt="thumbnail" />
                            </div>
                            <div className="thumb-box">
                                <img src="https://i.ibb.co/Yf9LMpy/thumb3.jpg" alt="thumbnail" />
                            </div>
                            <div className="thumb-box">
                                <img src="https://i.ibb.co/60hPGy2/thumb4.jpg" alt="thumbnail" />
                            </div>

                        </div> */}
                        
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ProductFull;