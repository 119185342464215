import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

// Register Chart.js modules
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SalesChart = () => {
  // Sales data for the past 12 months
  const salesData = [1200, 1500, 1100, 1700, 1800, 1400, 1600, 1900, 2000, 2200, 2100, 2400];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Chart data and configuration
  const data = {
    labels: months,
    datasets: [
      {
        label: "Monthly Revenue ($)",
        data: salesData,
        borderColor: "rgba(255, 0, 153, 1)",
        backgroundColor: "rgba(255, 0, 153, 0.2)",
        borderWidth: 2,
        pointRadius: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: true,
        text: "12-Month Sales Revenue - Current Drops",
        font: {
          size: 18,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Months",
        },
      },
      y: {
        title: {
          display: true,
          text: "Revenue ($)",
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default SalesChart;